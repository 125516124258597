import { define, ui, when, select, transform, s } from '@owenscorning/pcb.alpha';

import Renderer from './../../../../OC/PageBuilder/AttentionBarPicker.jsx';

const sizesHash = {
  '1-1': ['1/2 image', '1140px'],
  '1-2': ['1/3 image', '740px']
};

const availableLayouts = {
  'www.owenscorning.com': {
    custom: 'Custom',
    multiCTA: 'Multi CTA',
    enUs: 'Insulation - Find Our Products - en-US',
    enCa: 'Insulation - Find Our Products - en-CA',
    frCa: 'Insulation - Find Our Products - fr-CA',
    roofing: 'Roofing Products and Pros'
  },
  'www.paroc.com': {
    custom: 'Custom',
    multiCTA: 'Multi CTA',
  },
  'www.vidawool.com': {
    custom: 'Custom',
    multiCTA: 'Multi CTA',
  },
};

const Edit = (editOptions) => ({
  settings: {
    size: ui`Choices`.of({
      'full-bleed': 'Full Bleed',
      xl: 'XL',
      l: 'Large',
      m: 'Medium',
    })({
      label: 'Container Size',
      default: editOptions?.size?.default || 'xl',
      mode: ui`Choices/Mode/Dropdown`
    }),
    alignment: ui`Choices`.of({ 'left': 'Left', 'center': 'Center' })({
      label: 'Alignment',
      default: 'center',
      visible: when`../size`.is.equal.to('xl').or.when`../size`.is.equal.to('full-bleed').then(false).otherwise(true)
    }),
    attentionBarType: ui`Choices`.of({
      noImage: 'Standard',
      withImage: 'With Image'
    })({
      label: 'Type',
      default: 'noImage'
    }),
    animate: ui`Switch`({
      label: 'Animate Attention Bar',
      default: false,
    }),
    closeButton: ui`Switch`({
      label: 'Make Attention Bar Closeable'
    }),
    closeButton: transform`../../`((editorValues) => {
      // closed button cannot be used when layout is not "Custom" and attentionBarType is "noImage"
      if (editorValues?.settings?.attentionBarType === 'noImage') {
        if (editorValues?.layout !== 'custom') editorValues.settings.closeButton = false
      }
      return [ui`Switch`({
        label: 'Make Attention Bar Closeable',
        disabled: editorValues?.settings?.attentionBarType === 'noImage' && editorValues?.layout !== 'custom' ? true : false
      })
      ]
    }),
    [s._]: transform`../../`((editorValues) => {
      return [ui`Tip`.of('DISABLED: Only available for Custom Layout')({
        visible: editorValues?.settings?.attentionBarType === 'noImage' && editorValues?.layout !== 'custom' ? true : false,
      })]
    })
  },

  layout: ui`Choices`.of(availableLayouts[PB_SITE])({
    label: 'Layout',
    default: 'custom',
    visible: when`../settings/attentionBarType`.is.equal.to('noImage'),
  }),
  copy: [{
    prehead: ui`Text`({ label: 'Prehead', default: 'Prehead', visible: when`../../layout`.is.equal.to('custom') }),
    heading: ui`Text`({ label: 'Heading', default: 'Heading' }),
    content: ui`Text/Rich`({ label: 'Content', default: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.', visible: when`../../layout`.is.equal.to('custom') })
  }, {
    label: 'Custom',
    visible: when`../settings/attentionBarType`.is.equal.to('noImage').and.when`../layout`.is.equal.to('custom').or.when`../layout`.is.equal.to('multiCTA')
  }],
  cta: ui`CTA`({ visible: when`../settings/attentionBarType`.is.equal.to('noImage').and.when`../layout`.is.equal.to('custom') }),
  ctas: ui`CTA/List`({
    min: 1,
    visible: when`../settings/attentionBarType`.is.equal.to('noImage').and.when`../layout`.is.equal.to('multiCTA')
  }),
  layoutWithImage: ui`Form`.of({
    ratio: ui`Choices`.of({
      '1-1': '1/2 image and 1/2 content',
      '1-2': '1/3 image and 2/3 content'
    })
  })({
    label: 'Ratio',
    default: '1-1',
    visible: when`../settings/attentionBarType`.is.equal.to('withImage'),
  }),
  imageSizeInfo: ui`ImageSizeInfo`(
    {
      visible: when`../settings/attentionBarType`.is.equal.to('withImage'),
      moduleName: 'Attention Bar',
      sizesHash: sizesHash,
      selectedSize: select`../layoutWithImage/ratio`
    },
  ),
  imageWithImage: ui`Image`({ visible: when`../settings/attentionBarType`.is.equal.to('withImage'), crop: true }),
  contentWithImage: ui`Content/Basic`({ visible: when`../settings/attentionBarType`.is.equal.to('withImage') })
});

export { Edit };
export default define`Attention Bar`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/AttentionBarWithImage`,
  category: ui`Modules/Categories/Page/Customizable`,
  view: ({ value }) => <Renderer {...value} />,
  edit: Edit(),
  gutterlessForReusable: true,
});
