// TranslationStyles.js
import styled from '@emotion/styled';

export const TranslationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
`;

export const SourceContainer = styled.div`
  padding: 10px;
  width: 50%;
  padding-right: 5px;

  textarea {
    border-radius: 0px;
    border: 1px solid #000;
    width: 100%;
    height: 200px;
  }
`;

export const TargetContainer = styled.div`
  padding: 10px;
  width: 50%;
  padding-left: 5px;

  textarea {
    border-radius: 0px;
    border: 1px solid #000;
    width: 100%;
    height: 200px;
  }
`;

export const TargetTextContainer = styled.div`
  position: relative;
`;

export const ClipboardButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  content-visibility: hidden;
  content-visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const ButtonsContainer = styled.div`
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
`;

export const TranslateButton = styled.button`
  width: fit-content;
  padding: 10px 20px;
  background-color: #ff1493;
  background-color: ${props => (props.disabled ? '#ff69b4' : 'ff1493')};
  color: white;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
`;

export const ClearTranslateButton = styled.button`
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  content-visibility: hidden;
  content-visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const Legend = styled.legend`
  color: black;
  font-weight: 700;
  margin-bottom: 8px;
  width: 100%;
`;

export const HelperText = styled.span`
  color: #585858;
  font-size: 12px;
  font-weight: normal;
  margin-left: 8px;
  display: inline;
`;

export const MessageContainer = styled.div`
  background-color: ${props => props.backgroundColor || '#fff'};
  width: 100%;
  margin-top: 20px;
  padding: 15px;
`;

export const ProgressContainer = styled(MessageContainer)`
  background-color: #ebc173;
  color: #875a07;
`;

export const SuccessContainer = styled(MessageContainer)`
  background-color: #b9d3b9;
  color: green;
`;

export const ErrorContainer = styled(MessageContainer)`
  background-color: #d19797;
  color: red;
`;
