import { ui, select, s, when } from '@owenscorning/pcb.alpha';
import Product from './Product';
import { wrapRef } from '../../../../../data';
import { stringsFor } from '../../../../location/locales';
import _ from 'lodash';

const Roofing = Product(
  'Product::Roofing',
  ui`AttributeSet`({
    query: {
      name: 'Roofing PDP Attributes',
      path: '/roofing',
    },
  }),
  (build) => [
    { title: 'Advertisement', initial: [{ type: 'Reusable', data: { reference: wrapRef('Cms::Content', { type: 'Reusable', path: 'roofing', name: 'TPRS Full Bleed' }) } }] },
    { title: 'Call to Actions', initial: [{ type: 'Reusable', data: { reference: wrapRef('Cms::Content', { type: 'Reusable', path: 'roofing', name: 'Roofing Products & Contractors Attention Bar' }) } }] },
    { title: 'Contact Us', initial: [{ type: 'ContactUs', data: { preset: 'roofing' } }] },
  ],
  (build) => ({ url: `/${build.language.toLowerCase()}/roofing/contractors`, color: "standard", destination: "link", text: _.get(stringsFor(build.language), 'nav.roofing.find_a_contractor') }),
  {
    version: '0.1',
    displayName: 'Roofing PDP',
    paths: ['roofing/components/products'],
  },
  [
    {
      title: 'Intro',
      unmovables: [true],
      modules: ['BasicContent', 'MediaObjectSet', 'CardSet', 'Reusable'],
      // TODO, only for roofing component
      initial: ['RoofingIntroContentAndImage'],
      showInSticky: false
    },
    {
      title: 'Specifications & Literature',
      unmovables: [true],
      modules: ['FullBleedContent', 'Reusable'],
      initial: ['SpecificationsAndLiterature'],
      showHeading: true,
      translationKey: "pdp.roofing.sections.specifications_and_literature"
    },
    {
      title: 'Performance Attributes',
      modules: ['ContentAndImage', 'BasicContent', 'MediaObjectSet', 'CardSet', 'Reusable'],
      background: 'grey',
      showHeading: true,
      translationKey: "pdp.roofing.sections.performance_attributes"
    },
    {
      title: 'Resources',
      modules: ['ContentAndImage', 'BasicContent', 'Gallery', 'Reusable'],
      initial: [
        {
          type: 'Gallery',
          data: {
            settings: {
              thumbnail: 'large',
            },
          }
        }
      ],
      showHeading: true,
      translationKey: "pdp.roofing.sections.resources"
    },
    {
      title: 'Technical Information',
      modules: ['Table', 'BasicContent', 'ContentAndImage', 'FullBleedContent', 'Reusable'],
      initial: [
        {
          type: 'Table',
          data: {
            copy: {
              prehead: '',
              heading: '',
              bodyCopy: null
            },
          }
        },
        'ApplicableStandards'
      ],
      showHeading: true,
      translationKey: "pdp.roofing.sections.technical_information"
    },
    {
      title: 'Related Products',
      modules: [],
      initial: [
        {
          type: 'MediaObjectSet',
          data: {
            copy: {
              prehead: '',
              heading: '',
              bodyCopy: null
            },
            settings: {
              itemsPerRow: '2'
            },
          }
        }
      ],
      showHeading: true,
      translationKey: "pdp.roofing.sections.related_products"
    },
    {
      title: 'Talk to an Expert',
      modules: ['MediaObjectSet', 'Reusable'],
      showHeading: true,
      translationKey: "pdp.roofing.sections.talk_to_an_expert"
    },
    {
      title: 'Advertisement',
      modules: ['FullBleedContent', 'Reusable'],
      showInSticky: false,
      showHeading: false
    },
    {
      title: 'Call to Actions',
      modules: ['AttentionBar', 'Reusable'],
      showInSticky: false
    },
    {
      title: 'Contact Us',
      modules: [],
      initial: ['ContactUs'],
      translationKey: "pdp.contact_us"
    },
    {
      title: 'Performance Attributes',
      modules: ['MediaObjectSet'],
      background: 'grey',
      showHeading: true
    },
  ],
  "/roofing/components/products",
  'roofing',
)

export default Roofing;
