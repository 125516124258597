/* eslint-disable camelcase */
import _ from 'lodash';
import qs from 'qs';

import useMdmsApi, { SUCCESS } from '../../../hooks/use_mdms_api';

function parocProductUrl(localeCode) {
  // TODO: eventually will need to consider the perPage limit, default 100 there are 50 products on production today
  return `/api/v2/cms/sites/www.paroc.com/published?${qs.stringify({
    filter: {
      type: 'Cms::Content::Product::Paroc',
      'metadata.redirect': 'null',
      language_iso_code: localeCode,
      'metadata.settings.attributes.variants': '\x00',
    },
    fields: {
      '*': 'name,contents,metadata',
    },
    sort: 'name',
  })}`;
}

function flattenProducts(data = []) {
  return _.flatMap(data, product => {
    if (!product?.metadata?.settings) {
      console.warn('Product missing settings!', { product });
      return [];
    }
    const {
      name,
      metadata: {
        settings: { attributes = {} },
      },
    } = product;
    const { family, variants = [] } = attributes;
    return variants.map(
      ({
        base_value_1,
        base_value_2,
        density,
        epd_locale,
        epd_slug,
        lambda_value,
        scaling_factor,
        thickness,
      }) => ({
        family,
        sku: name,
        base_value_1: Number(base_value_1),
        base_value_2: Number(base_value_2),
        density: Number(density),
        epd_locale,
        epd_slug,
        lambda_value: Number(lambda_value),
        scaling_factor: Number(scaling_factor),
        thickness: Number(thickness),
      })
    );
  });
}

/**
 *
 * @param {string} localeCode
 * @returns {{
 *  status: string,
 *  products: {
 *   base_value_1: number,
 *   base_value_2: number,
 *   density: number,
 *   epd_locale: string,
 *   epd_slug: string,
 *   family: string,
 *   lambda_value: number,
 *   scaling_factor: number,
 *   sku: string,
 *   thickness: number
 *  }[]
 * }}
 */
export default function useParocData(localeCode) {
  const { status, data: response } = useMdmsApi(
    parocProductUrl(localeCode),
    localeCode
  );
  if (status === SUCCESS) {
    const products = flattenProducts(response?.data);
    return { status, products };
  }
  return { status, products: [] };
}
