import PropTypes from 'prop-types';

import CTAButton from '../../../CTAButton';
import RichText from '../../../OC/PageBuilder/RichText';
import Text from '../../../OC/PageBuilder/utilities/Text';
import FigureOverlay from '../../../OC/oc-figure-overlay';
import { connectLocation } from '../../../location/LocationConnectors';
import ColorPalette from '../ColorPalette';

const PorchVignette = ({
  description,
  colorPalette,
  mainShingle,
  colorName,
  shingleSelected,
  porchSection,
  referenceGuide,
  file,
  t,
}) => {
  if (!shingleSelected || !shingleSelected.results) {
    return null;
  }

  const isMainShingleSelected =
    shingleSelected.results.data.shingle_colors?.[0].name ===
    mainShingle.results.data.shingle_colors?.[0].name;
  const colorSelectedData = shingleSelected.results.data;
  const colorSelectedImageData = colorSelectedData.shingle_colors?.[0];

  const overlays = isMainShingleSelected
    ? []
    : [
        {
          position: { x: 0, y: 0 },
          src: { file: colorSelectedImageData.tile_image_url2 },
          width: 439,
          gravity: 'north_west',
          isTiled: true,
          basedImageWidth: 500,
          widthBased: true,
          cropSettings: {
            crop: 'crop',
            height: 84,
            width: 439,
            gravity: 'north_west',
          },
        },
      ];

  return (
    <div className="columns ratio-1-1 about-section">
      <div className="col">
        <h1
          className="tier3"
          style={{ marginBottom: isMainShingleSelected ? '0' : '20px' }}
        >
          {isMainShingleSelected
            ? t('design_inspire.style_boards.about_this_pairing')
            : `${colorSelectedImageData.name.toUpperCase()} & ${colorName}`}
        </h1>
        {isMainShingleSelected && <RichText content={description} />}
        <div className="columns ratio-1-1">
          <div className="col">
            <div className="shingle-img-container">
              <img
                className="shingle-img"
                src={colorSelectedImageData.swatch_768x768_url}
                alt={colorSelectedImageData.swatch_alt_en}
              />
            </div>
            <p className="shingle-name-prehead">
              <Text
                content={
                  colorSelectedData.trudefinition ? 'TruDefinition® ' : null
                }
              />
              <Text content={colorSelectedData.proper_name} />
            </p>
            <div className="col shingle-name-heading">
              <h2 className="h4 body-font">
                <Text content={colorSelectedImageData.name} />
              </h2>
            </div>
          </div>
          <div className="col">
            <div className="shingle-img-container">
              <ColorPalette
                colorPalette={colorPalette}
                mode="vertical"
                heading={t('design_inspire.style_boards.color_palette')}
                prehead={colorName}
                height="270px"
                tabletHeight="170px"
              />
            </div>
          </div>
        </div>
        {referenceGuide && file && (
          <div className="columns ratio-1-3 reference-guide-img-container">
            <div className="col">
              <img
                src={referenceGuide?.img_path}
                alt={referenceGuide?.img_alt}
                width="100%"
              />
            </div>
            <div className="col reference-guide-container">
              <h4>{t('design_inspire.color_palette_reference_guide')}</h4>
              <p>{t('design_inspire.color_palette_learn_more')}</p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={file?.url}
                data-track="file-download"
                data-track-doc-type=""
                data-track-file-name="summary"
                data-track-file-type="pdf"
              >
                <i className="fa fa-file-pdf-o" aria-hidden="true" />
                <span> {t('design_inspire.shingle_reference_guide_pdf')}</span>
              </a>
            </div>
          </div>
        )}
        <CTAButton
          href={`/en-us/roofing/shingles/${colorSelectedData.uid}?color=${colorSelectedImageData.uid}`}
        >
          {t('design_inspire.view_product_details')}
        </CTAButton>
        <br />
        <CTAButton
          href="/en-us/roofing/roof-visualizer"
          className="oc-cta-button transparent oc-icon favorites see-on-my-house-btn"
        >
          {t('design_inspire.see_it_on_my_house')}
        </CTAButton>
      </div>
      <div className="col">
        <FigureOverlay
          className="porch-vignette-image"
          size="full-bleed"
          baseLayer={{ src: { ...porchSection } }}
          overlays={overlays}
        />
      </div>
    </div>
  );
};

PorchVignette.propTypes = {
  description: PropTypes.shape({
    ops: PropTypes.arrayOf({
      content: PropTypes.string,
    }),
  }).isRequired,
  colorPalette: PropTypes.shape({
    color1: PropTypes.string,
    color2: PropTypes.string,
    color3: PropTypes.string,
    color4: PropTypes.string,
  }).isRequired,
  colorName: PropTypes.string.isRequired,
  shingleSelected: PropTypes.shape({
    results: {
      data: {
        shingle_colors: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          })
        ),
      },
    },
  }).isRequired,
  mainShingle: PropTypes.shape({
    results: {
      data: {
        shingle_colors: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
          })
        ),
      },
    },
  }).isRequired,
  porchSection: PropTypes.shape({
    file: PropTypes.string,
    alt: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  referenceGuide: PropTypes.shape({
    img_path: PropTypes.string,
    img_alt: PropTypes.string,
  }),
  file: PropTypes.shape({
    url: PropTypes.string,
  }),
};

PorchVignette.defaultProps = {
  referenceGuide: null,
  file: null,
};

export default connectLocation(PorchVignette);
