import def_0 from "./Cms/Asset";
import def_1 from "./Cms/Content";
import def_2 from "./Cms/DataProvider";
import def_3 from "./Document";
import def_4 from "./ShingleColor";



export default {
  "Cms": {
    "Asset": def_0,
    "Content": def_1,
    "DataProvider": def_2
  },
  "Document": def_3,
  "ShingleColor": def_4
};