import React, { useState } from 'react';

import SelectDropdown from './ComponentLibrary/input-elements/oc-select-dropdown';
import { TRANSLATION_URL } from './Constants';
import {
  TranslationContainer,
  HeadingContainer,
  LanguageContainer,
  SourceContainer,
  TargetContainer,
  TargetTextContainer,
  ClipboardButton,
  ButtonsContainer,
  TranslateButton,
  ClearTranslateButton,
  Legend,
  HelperText,
  ProgressContainer,
  SuccessContainer,
  ErrorContainer,
} from './TranslationStyle';
import { dmras } from '../dmras_api';

const sourceLanguages = [{ value: 'en', text: 'English' }];
const targetLanguages = [
  { value: 'es', text: 'Spanish' },
  { value: 'fr', text: 'French' },
  { value: 'de', text: 'German' },
  { value: 'zh', text: 'Chinese' },
];
const translationVendors = [{ value: 'deepl', text: 'DeepL' }];
const translationVendorsDefault = 'deepl';
const defaultSourceLang = 'en';
const defaultTargetLang = 'es';

const Translation = () => {
  const [sourceLanguage, setSourceLanguage] = useState(defaultSourceLang);
  const [targetLanguage, setTargetLanguage] = useState(defaultTargetLang);
  const [sourceText, setSourceText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [disableTranslateButton, setDisableTranslateButton] = useState(true);
  const [visibleClipboardButton, setVisibleClipboardButton] = useState(false);
  const [
    visibleClearTranslationButton,
    setVisibleClearTranslationButton,
  ] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(null);

  const handleTranslate = async () => {
    setLoading(true);
    setVisibleClipboardButton(true);
    setVisibleClearTranslationButton(true);

    try {
      const data = {
        data: {
          machineTranslationVendor: 'deepl',
          targetLanguage,
          // sourceLanguage: sourceLanguage,
          keyPrefix: 'manual',
          texts: [
            {
              key: 'some-random-key',
              content: sourceText,
            },
          ],
        },
      };

      const response = await dmras.post(TRANSLATION_URL, data);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTranslatedText(result.data.translatedTexts[0].content);
    } catch (error) {
      setError('Unsuccessful translation');
      console.log('Error: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearTranslation = () => {
    setTranslatedText('');
    setVisibleClipboardButton(false);
    setVisibleClearTranslationButton(false);
    setError('');
  };

  const handleSourceTranslationChange = val => {
    setSourceText(val);
    setDisableTranslateButton(val === '');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(translatedText);
  };

  return (
    <TranslationContainer>
      <HeadingContainer>
        <h3>TRANSLATE</h3>

        <div>
          <Legend>
            Translation Vendor <HelperText>Required</HelperText>
          </Legend>
          <SelectDropdown
            name="DeepL"
            options={translationVendors}
            defaultValue={translationVendorsDefault}
          />
        </div>
      </HeadingContainer>
      {Loading && (
        <ProgressContainer>Translation in Progress.</ProgressContainer>
      )}
      {translatedText && !Loading && (
        <SuccessContainer>Successful translation</SuccessContainer>
      )}
      {Error && <ErrorContainer>{Error}</ErrorContainer>}
      <LanguageContainer>
        <SourceContainer>
          <div>
            <Legend>
              Source Language <HelperText>Required</HelperText>
            </Legend>
            <SelectDropdown
              name="sourceLanguage"
              options={sourceLanguages}
              defaultValue={defaultSourceLang}
              onChange={e => setSourceLanguage(e.target.value)}
            />
          </div>
        </SourceContainer>

        <TargetContainer>
          <div>
            <Legend>
              Translation Vendor <HelperText>Required</HelperText>
            </Legend>
            <SelectDropdown
              name="targetLanguage"
              options={targetLanguages}
              defaultValue={defaultTargetLang}
              onChange={e => setTargetLanguage(e.target.value)}
            />
          </div>
        </TargetContainer>
      </LanguageContainer>

      <LanguageContainer>
        <SourceContainer>
          <textarea
            value={sourceText}
            onChange={e => handleSourceTranslationChange(e.target.value)}
            placeholder="Enter text here"
          />
        </SourceContainer>

        <TargetContainer>
          <TargetTextContainer>
            <textarea value={translatedText} readOnly />
            <ClipboardButton
              onClick={copyToClipboard}
              visible={visibleClipboardButton}
            >
              <i className="fa fa-copy" />
            </ClipboardButton>
          </TargetTextContainer>
        </TargetContainer>
      </LanguageContainer>

      <ButtonsContainer>
        <ClearTranslateButton
          onClick={handleClearTranslation}
          visible={visibleClearTranslationButton}
        >
          Clear Translation
        </ClearTranslateButton>
        <TranslateButton
          onClick={handleTranslate}
          disabled={disableTranslateButton}
        >
          TRANSLATE
        </TranslateButton>
      </ButtonsContainer>
    </TranslationContainer>
  );
};

export default Translation;
