const defineApi = (baseUrlFactory, baseUrlEnvName) => {
  const validateBaseUrl = () => {
    const base = baseUrlFactory();
    if (typeof base === 'undefined' || !base) {
      const message = `${baseUrlEnvName} must be set in process.env, check your \`.env\` file`;
      console.error('Error: ', message);
      if (process.env.NODE_ENV === 'development') {
        alert(message);
      }
      return Promise.reject(new Error(message));
    }
    return Promise.resolve(base);
  };

  return {
    post: (url, data, options = {}) =>
      validateBaseUrl().then(base =>
        fetch(base + url, {
          ...(options || {}),
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },

          body: JSON.stringify(data),
        })
      ),
  };
};

export const dmras = defineApi(() => DMRAS_URL, 'DMRAS_URL');

// SAMPLE
// import { dmras } from 'dmras_api'
// ...
// dmras.post('/api/v1/...')
