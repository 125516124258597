import { css } from '@emotion/react';
import Theme from '../../../../../themes';

const list = {
  sets: (index = -1) => Array(9).fill().map((value, counter) => counter > index ? `list-${ counter + 1 }` : false).filter(Boolean).join(' '),
  character: (index) => [ 'lower-alpha', 'lower-roman', 'decimal' ][ index % 3 ]
};

export default {
  base: css`
    .ql-container {
      font-family: "OCRoboto", sans-serif;
    }

    .ql-toolbar button {
      background: transparent !important;
      box-shadow: 0px 0px 0px transparent !important;
      text-shadow: 0px 0px 0px transparent !important;
      outline: none !important;
      padding: 0px !important;
      border: 0 !important;
      -webkit-appearance: none;
      appearance: none;
    }

    .ql-editor {
      font-size: 16px;
      outline: none;
    }

    .ql-editor p:first-child {
      margin-top: 0;
    }

    .ql-editor p:last-child {
      margin-bottom: 0;
    }

    .ql-editor a:not(:hover) {
      color: inherit;
    }

    .ql-editor a:hover {
      color: ${Theme.colors.brand};
    }

    .ql-clipboard {
      left: -100000px;
      height: 1px;
      overflow-y: hidden;
      position: absolute;
      top: 50%;
    }

    .ql-clipboard p {
      margin: 0;
      padding: 0;
    }

    mark {
      background-color: transparent !important;
      color: inherit;
    }

    mark.highlight {
      font-style: inherit;
      color: ${ Theme.colors.brand };
    }

    div.disclaimer {
      font-size: 12px;
    }

    div.smaller {
      font-size: 14px;
    }

    div.document {
      margin-top: 16px;
    }

    div.document a {
      text-decoration: none;
    }

    div.document a:hover .document-text {
      text-decoration: underline;
    }

    div.document .document-icon {
      text-decoration: none;
      padding-right: 10px;
    }

    div.document .document-size {
      text-decoration: none;
      font-weight: 300;
      font-size: 12px;
      padding-left: 8px;
    }

    div + p br {
     display: none;
    }
  `,
  list: css`
    ol, ul { padding-left: 1.5em; }
    ol > li, ul > li { list-style-type: none; }
    ul > li::before { content: '\\2022'; }

    li::before {
      display: inline-block;
      white-space: nowrap;
      width: 1.2em;
    }

    li:not(.ql-direction-rtl)::before {
      margin-left: -1.5em;
      margin-right: 0.3em;
      text-align: right;
    }

    li.ql-direction-rtl::before {
      margin-left: 0.3em;
      margin-right: -1.5em;
    }

    ol li:not(.ql-direction-rtl), ul li:not(.ql-direction-rtl) { padding-left: 1.5em; }
    ol li.ql-direction-rtl, ul li.ql-direction-rtl { padding-right: 1.5em; }

    ol li {
      counter-increment: list-0;
      counter-reset: ${ list.sets() };
    }

    ol li:before { content: counter(list-0, decimal) '. '; }

    ${new Array(8).fill().map((value, index) => {
      const indentation = index + 1;
      return css`
        ol li.ql-indent-${ indentation } { counter-increment: list-${ indentation }; }
        ol li.ql-indent-${ indentation } { counter-reset: ${ list.sets(index) }; }
        ol li.ql-indent-${ indentation }:before { content: counter(list-${ indentation }, ${ list.character(index) }) '. '; }
        .ql-indent-${ indentation }:not(.ql-direction-rtl) { padding-left: ${ 3 * indentation }em; }
        li.ql-indent-${ indentation }:not(.ql-direction-rtl) { padding-left: ${ 1.5 + (3 * indentation) }em; }
        .ql-indent-${ indentation }.ql-direction-rtl.ql-align-right { padding-right: ${ 3 * indentation }em; }
        li.ql-indent-${ indentation }.ql-direction-rtl.ql-align-right { padding-right: ${ 1.5 + (3 * indentation) }em; }
      `;
    })}
  `,
  full: css`
    .ql-container {
      border: 1px solid #585858;
      background-color: #FFF;
      color: #333;
    }
    .ql-editor {
      padding: 12px 8px;
    }
  `
}
