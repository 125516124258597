import styled from '@emotion/styled';
import Image from './oc-image';
import { H1 } from './oc-h';
import Picture from "./oc-picture";
import Text from './oc-text';
import placeholder_url from '../../placeholder';

const desktopImageSize = {
  desktop: {
    sm: ` max-width: 180px;
          max-height: 72px;
        `,
    lg: ` max-width: 300px;
          max-height: 120px
        `,
    xl: ` max-width: 400px;
          max-height: 160px
        `,
  },
  tablet: {
    sm: ` max-width: 160px;
          max-height: 64px;
        `,
    lg: ` max-width: 250px;
          max-height: 100px
        `,
    xl: ` max-width: 350px;
          max-height: 140px
        `,
  },
  mobile: {
    sm: ` max-width: 100px;
          max-height: 40px;
        `,
    lg: ` max-width: 180px;
          max-height: 72px;
        `,
    xl: ` max-width: 280px;
          max-height: 112px;
        `,
  }
};

const HeroContainer = styled.div`
  align-items: flex-start;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media(min-width: 768px) {
    align-items: flex-end;
    background-image: url(${props => props.imgTablet});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 121px;
  }

  @media (min-width: 1200px) {
    background-image: url(${props => props.imgDesktop});
    min-height: 164px;
  }
`;

const TopFill = styled.div`
  padding: 0 16px;
  display: inline-block;
  text-align: left;

  @media(min-width: 768px) {
    background: white;
    padding: 0 24px;
    width: 718px;
    margin-right: 80px;
    text-align: right;
  }

  @media(min-width: 1200px) {
    width: 1176px;
    margin-right: 164px;
  }

  img {
    margin-bottom: 8px;
    ${props => desktopImageSize.mobile[props.logoSize]}

    @media(min-width: 768px) {
      ${props => desktopImageSize.tablet[props.logoSize]}
    }

    @media(min-width: 1200px) {
      ${props => desktopImageSize.desktop[props.logoSize]}
    }
  }
`;

const MiddleFill = styled.div`
  display: inline-block;
  text-align: left;
  padding: ${props => props.standalone ? '0' : '0 16px' };

  @media(min-width: 768px) {
    background: white;
    padding: 0 24px;
    width: 420px;
    margin-right: 80px;
    min-height: 61px;
    text-align: right;
  }

  @media(min-width: 1200px) {
    width: 584px;
    margin-right: 164px;
    min-height: 96px;
  }

  h1 {
    margin-bottom: 16px;

    @media(min-width: 1200px) {
      margin-bottom: 24px;
    }
  }
`;

const BottomFill = styled.div`
  display: block;
  text-align: left;

  @media(min-width: 768px) {
    background: white;
    padding: 0 24px 32px;
    width: 386px;
    margin-right: 80px;
    text-align: right;
    min-height: 60px;
  }

  @media(min-width: 1200px) {
    width: ${props => !props.cutawayImage ? '500px' : '584px'};
    margin-right: 164px;
    min-height: 68px;
  }

  @media(max-width: 768px){
    padding-left: 16px;
    padding-right: 16px;
  }

  div {
    margin: 0;
  }

  p {
    text-align: left;
    margin: 0 0 16px!important;

    @media(min-width:  768px) {
      max-width: 350px;
      margin: 0 0 24px 38px!important;
    }

    @media(min-width:  1200px) {
      max-width: 432px;
      margin: 0 0 24px 52px!important;
    }
  }
`;

const RightFill = styled.div`
  background: white;
  position: absolute;
  bottom: 0;

  @media(min-width:  768px) {
    width: 50px;
    height: 148px;
    right: 30px;
  }

  @media(min-width:  1200px) {
    width: ${props => !props.cutawayImage ? '100px' : '90px'};
    height: 146px;
    right: ${props => !props.cutawayImage ? '64px' : '75px'};
  }
`;

const CtaContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-start;

  @media(min-width:  768px) {
    justify-content: flex-end;
  }

  .oc-cta-container {
    margin-left: 16px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
`
const ImageWrapper = styled.span`
  align-items: flex-end;
  background-color: white;
  display: flex;

  img {
    @media(min-width: 768px) {
      ${props => desktopImageSize.tablet[props.cutawayImageSize]}
    }

    @media(min-width: 1200px) {
      ${props => desktopImageSize.desktop[props.cutawayImageSize]}
    }
  }

  @media(min-width: 768px) {
    padding-left: 20px;
    padding-top: 2%;
    align-items: flex-start;
  }

  @media(min-width: 1200px) {
    padding-left: 20px;
    padding-right: 15px;
    height: max-content;
    align-self: flex-end;
    padding-top: 4%;
  }

  @media(max-width: 768px) {
      display: none;
      width: 0;
      height: 0;
  }
`;

const defaults = {
  heroDesktop: placeholder_url(2680,490)
}

const HeroT2 = ({ imgAlt, imgDesktop, imgTablet, prehead, h1, body, ctas, logoSize = 'sm', logo, logoAlt, standalone, cutawayImage, cutawayImageSize = 'sm', cutawayImageAlt, ...props }) => {
  let processedImgDesktop = Picture.DefaultOrCrop(imgDesktop?.file, imgDesktop?.crop, defaults.heroDesktop);
  let processedImgTablet = Picture.DefaultOrCrop(imgTablet?.file, imgTablet?.crop, imgDesktop?.file || defaults.heroDesktop);

  return (
    <HeroContainer imgDesktop={processedImgDesktop} imgTablet={processedImgTablet} aria-label={imgAlt} role="img">
      { (prehead || logo) &&
        <TopFill logoSize={logoSize}>
          {logo && <Image src={logo} alt={logoAlt} />}
          {prehead && <span className="prehead tier2"><Text content={ prehead } /></span>}
        </TopFill>
      }

      <MiddleFill standalone={standalone}>
        { standalone ?
          <H1 className="tier2" dangerouslySetInnerHTML={{ __html: h1 }} />
        :
          <H1 className="tier2">{h1}</H1>
        }
      </MiddleFill>
      <Wrapper>
        {cutawayImage && <ImageWrapper cutawayImage={cutawayImage} cutawayImageSize={cutawayImageSize}>
          {cutawayImage && <Image src={cutawayImage} alt={cutawayImageAlt} />}
        </ImageWrapper>}
      <BottomFill cutawayImage={cutawayImage}>
        { body &&
          standalone ? <p dangerouslySetInnerHTML={{ __html: body }} /> : body
        }

        { ctas &&
          <CtaContainer>
            {ctas}
          </CtaContainer>
        }
      </BottomFill>
      </Wrapper>
      <RightFill />
    </HeroContainer>
  );
};

export default HeroT2;
