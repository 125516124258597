import { css } from "@emotion/react";
import _ from 'lodash';
import styled from '@emotion/styled';
import MediaObject from '../../../OC/PageBuilder/MediaObject';
import itemListResponse from './helpers/item_list_response';
import Card from '../../../OC/oc-card-new';
import { H3 } from '../../../OC/oc-h';
import Text from '../../../OC/PageBuilder/utilities/Text';
import { useCloudinaryPictureOverlay } from "../../../OC/oc-picture-overlay";
import ColorPalette from '../../../Roofing/DesignAndInspire/ColorPalette';
import joinPath from '../../../../helpers/joinPath';
import Grid from '../../../OC/PageBuilder/Grid';
import Tab from '../../../ComponentLibrary/oc-tab';
import TabList from '../../../ComponentLibrary/oc-tabs';
import { SortDropDown } from '../../../SDS/SortDropDown';
import ColorFilter from '../../../ComponentLibrary/filter-tree/oc-color-filter';
import CheckboxSelector from '../../../ComponentLibrary/filter-list/oc-filters';
import TextSearchFilter from "../../../ComponentLibrary/filter-list/oc-text-search-filter";
import useReference from "../../../../hooks/use_reference";
import { imageOverlayConfig } from "../../../Roofing/DesignAndInspire/utils/imageOverlayConfig";

const basePath = '/en-us/roofing/design-inspire';

const GroupSection = styled.section`
  margin-top: 0;
  margin-bottom: 32px;
  padding-bottom: 32px !important;
  &:last-of-type {
    border: none;
  }
`;

const CardLink = styled.a`
  text-decoration: none;

  &:hover {
    h2 {
      color: #d40f7d!important;
      text-decoration: underline;
    }
  }
`;

const withPairingItem = (WrappedComponent) => (item) => {
  const content = item?.contents?.[0];
  // console.log("withPairingItem", item);
  //Commenting the line below because of error: Rendered fewer hooks than expected. This may be caused by an accidental early return statement.
  // const { results, error, loading } = useReference(content.shingleColor?.color);
  const shingleColorData = content?.shingleColor?.color?.__data;
  const shingle = shingleColorData?.data;
  const shingleColor = shingle?.shingle_colors?.[0];
  const shingleColorRef = content?.shingleColor?.color?.__ref;

  return (
    <CardLink href={ `/${item?.language_iso_code?.toLowerCase()}${item?.route}` } data-track="learn-more" data-track-destination={`${content.colorName} Detail Page`} data-track-element-location="main section CTA">
      <WrappedComponent { ...content } shingle={ shingle } shingleColor={ shingleColor } shingleColorData={ shingleColorData } shingleColorRef={shingleColorRef} />
    </CardLink>
  );
}

const StyleBoardViewComponent = withPairingItem(({ heroImage, colorName, shingleColor, shingleColorRef }) => {
  const shingleColorData = useReference({ __ref: shingleColorRef }) || {};
  const { results = {} } = shingleColorData || {};
  const { data = {} } = results;
  const { shingle_colors: shingleColors = [] } = data;
  const { baseLayer, overlays } = imageOverlayConfig({
    shingleColor: shingleColors.length > 0 ? { ...shingleColors[0] } : {},
    heroImage,
  });
  baseLayer.desktop.scaleSettings = { width: 1146, height: 612 };
  overlays.desktop[0].position = { x: -15, y: 100 };
  overlays.desktop[1] = {
    ...overlays.desktop[1],
    position: { x: 345, y: 45 },
    fontSize: shingleColor.name.length > 15 ? 26 : 32,
  };
  const { imageUrl } = useCloudinaryPictureOverlay({
    baseLayer: baseLayer.desktop,
    overlays: overlays.desktop
  });

  return (
    <Card img={imageUrl} alt={heroImage?.desktop?.alt} transform="none">
      <H3 font="body" tag="h3"><Text content={`${ colorName } & ${ shingleColor?.name }`} /></H3>
    </Card>
  )
});

const ColorPaletteViewComponent = withPairingItem(({ colorName, colorPalette }) => (
  <ColorPalette heading={ colorName } colorPalette={ colorPalette } mode="horizontal" height="250px" />
));

const StyleBoardResultComponent = ({...props}) => {
  return(
    <>
      {StyleBoardViewComponent(props.item)}
    </>

  )};

const ColorPaletteResultComponent = ({...props}) => {
  return (
    <>
      {ColorPaletteViewComponent(props.item)}
    </>
  )
}

const StyleBoardGrouping = ({groupKey, children, title}) => {
  return (
    <GroupSection className="border">
      {groupKey.toString() === 'true' && <h2 className="h3"><Text content={`Featured ${title}`} /></h2>}
      <Grid selectedSize="3" selectedSizeTablet={groupKey.toString() === 'true' ? '3' : '2'} selectedSizeMobile={groupKey.toString() === 'true' ? '1' : '2'} >
        { children }
      </Grid>
    </GroupSection>
  )
}

const tabs = [
  { component: StyleBoardResultComponent, name: 'style-boards', title: 'Style Boards', displayStyle: 'grid', default: true, grouping: { key: 'featured', title: "Style Board", component: StyleBoardGrouping } },
  { component: ColorPaletteResultComponent, name: 'color-palettes', title: 'Color Palettes', displayStyle: 'grid', grouping: { key: 'featured', title: "Color", component: StyleBoardGrouping } },
];

const HeadingContainer = styled.div`
  align-items: center;
  display: inline-flex;
  position: relative;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: auto;
  }

  // Temp solution for mobile styling tabs
  + .tabs-list {
    @media screen and (max-width: 600px) {
      margin-top: 0.5em
    }
  }
`;

const WrappedH2 = styled.h2`
  margin-bottom: 0;
  margin-right: 0.5em;
`;

const WrappedTabs = styled(TabList)`
  margin-bottom: 0;
  margin-left: inherit !important;
  align-self: center !important;
  button {
    width: 185px;
  }
  button.active{
    border-bottom: 4px solid #d40f7d;
  }
`;

const ContentHeader = (props) => {
  const {count, activeTab, toggleTab, activeTitle, sortContent} = props
  const SortComponent = sortContent.component;
  return (
    <>
      <HeadingContainer>
        {<WrappedH2><span>{count}</span> {activeTitle}</WrappedH2> }
      </HeadingContainer>

      <WrappedTabs className="tabs-list">
        <Tab label="Style Boards" name="style-boards" active={activeTab === 'style-boards'} onClick={() => toggleTab("style-boards")} />
        <Tab label="Color Palettes" name="color-palettes" active={activeTab === 'color-palettes'} onClick={() => toggleTab("color-palettes")} />
      </WrappedTabs>

      {SortComponent ? (<SortComponent styleProp={sortStyle} {...sortContent.props} />) : null}
    </>
  )
};

const sortStyle = css`
  label {
    font-weight: 700 !important;
  }
  @media only screen and (max-width: 1199px) {
    margin-top: 24px;
  }
`;

const contentHeaderStyle = css`
  padding-top: 0 !important;
`;

const sorts = {
  fields: {
    featured: {
      attribute: 'name',
      displayName: 'Featured',
      direction: 'asc',
      default: true,
      group: true,
    },
    name_asc: {
      attribute: 'name',
      displayName: 'Alphabetical (A-Z)',
      direction: 'asc',
    },
    name_desc: {
      attribute: 'name',
      displayName: 'Alphabetical (Z-A)',
      direction: 'desc',
    },
  },
  component: SortDropDown,
  props: {label: 'Sort By'},
};

const primaryColorDisplayName = (str) => str.charAt(0).toUpperCase() + str.slice(1) + 's';

const getUniqArray = (array) => Array.from(new Set(array.map(JSON.stringify))).map(JSON.parse);

const colorFilter = {
  redAndPink: 'Red & Pink',
  orange: 'Orange',
  yellow: 'Yellow',
  green: 'Green',
  blue: 'Blue',
  purple: 'Purple',
  brown: 'Brown',
  neutralAndGray: 'Neutral & Gray',
  blackAndWhite: 'Black & White',
};

//used for sorting of filters
const compare = (a, b) => {
  return Object.values(a)[1] > Object.values(b)[1]
    ? 1
    : Object.values(b)[1] > Object.values(a)[1]
      ? -1
      : 0;
};

const searchFields =  ['name', 'accent_colors', 'main_house_colors', 'shingle_color_names', 'primary_shingle_colors'];

const colorPairingListResponse = (pairingData) => {
  const primaryShingleColors = [],
    colorNames = [],
    mainHouseColor = [],
    accentColor = [];

  const getShingleColors = (item) => {
    const content = item?.contents?.[0];
    //Commenting the line below because of error: Rendered fewer hooks than expected. This may be caused by an accidental early return statement.
    // const { results, error, loading } = useReference(content.shingleColor?.color);
    const results = content?.shingleColor?.color?.__data;
    const shingle = results?.data;
    return shingle?.shingle_colors;
  }

  const getColors = (item) => {
    const colors = []
    getShingleColors(item)?.forEach(shingleColor => shingleColor.colors.forEach(color => colors.push(color)));
    return colors
  }

  pairingData.forEach(pairing_item => {
    getColors(pairing_item).forEach(color => ( primaryShingleColors.push({value: color, displayName: primaryColorDisplayName(color)} )));
    getShingleColors(pairing_item).forEach(shingleColor => colorNames.push({value: shingleColor.uid, displayName: shingleColor.name}));
    let filtering = pairing_item.metadata?.settings?.filtering;
    filtering?.mainHouseColor?.forEach(color => ( mainHouseColor.push( {value: color, displayName: colorFilter[color]} )));
    filtering?.accentColor?.forEach(color => (accentColor.push( {value: color, displayName: colorFilter[color]} )));
  });

  const parseData = (items) => {
    return items.map(item => {
      return {
        primary_shingle_colors: getColors(item),
        shingle_color_names: getShingleColors(item).map(shingleColor => shingleColor.uid),
        main_house_colors: item.metadata?.settings?.filtering?.mainHouseColor,
        accent_colors: item.metadata?.settings?.filtering?.accentColor,
        name: `${item.name} & ${getShingleColors(item).map(shingleColor => shingleColor.name)[0]}`,
        item: item,
        featured: item.contents[0].featured || false,
      }
    });
  };

  const filters = {
    primary_shingle_colors: {
      component: ColorFilter,
      displayName: 'Primary Shingle Color Blend',
      options: getUniqArray(primaryShingleColors).sort(compare),
      valueType: 'multiple'
    },
    shingle_color_names: {
      component: CheckboxSelector,
      displayName: "Shingle Color Names",
      options: getUniqArray(colorNames).sort(compare),
      valueType: 'multiple',
    },
    main_house_colors: {
      component: CheckboxSelector,
      displayName: "Main House Color",
      options: getUniqArray(mainHouseColor).sort(compare),
      valueType: 'multiple',
    },
    accent_colors: {
      component: CheckboxSelector,
      displayName: "Accent Color",
      options: getUniqArray(accentColor).sort(compare),
      valueType: 'multiple',
    },
  };

  return itemListResponse({
      contentHeader: { component: ContentHeader, props: { headerStyle: contentHeaderStyle } },
      items: parseData(pairingData),
      filters,
      tabs,
      sorts,
      primaryActiveDefault: true,
      filterChipsVisible: false,
      Component: (props) => <MediaObject imgSize="large" { ...props } />,
      searchFields,
      enableSearch: true,
    });
}

export default {
  ItemList: {
    availableIn: ['www.owenscorning.com'],
    name: 'Roofing - Design and Inspire - Color Pairings',
    meta: {},
    view: (data = null) => {
      const { data: items } = (data || { meta: {} });
      return colorPairingListResponse((items || []).filter(x => x))
    }
  },
}
