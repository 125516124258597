import styled from '@emotion/styled';

// Components
import { H1 } from './oc-h';
import Text from './oc-text';
import RichText from './oc-rich-text';
import Picture from './oc-picture';
import NoImageHero from './oc-hero-no-image';
import CMSContentAndImage from './PageBuilder/ContentAndImage';

// Utilities
import placeholder_url from '../../placeholder';

// Styled Components
const HeroContainer = styled.div`
  background-size: cover;

  @media(min-width: 768px) {
    background-image: url(${props => props.imgTablet});
    background-repeat: no-repeat;
  }

  @media (min-width: 1200px) {
    background-image: url(${props => props.imgDesktop});
    background-size: 1340px;
    height: 245px;
  }

  @media (min-resolution: 2dppx) and (min-width: 1200px) {
    /* background-image: url(${props => Picture.Transform(props.hero_2x)}); */
  }
`;

const HeroGrid = styled.div`
  @media screen and (min-width: 768px) {
    height: 200px;
    position: relative;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    height: 245px;
  }
`;

const HeroPageHeading = styled.div`
  padding: 0 15px;

  @media(min-width: 768px) {
    background: white;
    left: 0px;
    max-width: 50%;
    padding: 0 15px 15px 15px;
    position: absolute;
    top: 0px;
  }

  @media screen and (min-width: 1200px) {
    padding: 1px 30px 30px 86px;
  }
`;

const PostHead = styled.span`
  color: #6D6D6D;
  display: block;
  font-family: "OCRoboto", sans-serif;
  font-size: 16px;
  line-height: 16px;
  margin: 16px 0 0;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const BottomRightFill = styled.div`
  @media (min-width: 768px) {
    background: white;
    bottom: 0px;
    height: 40px;
    position: absolute;
    right: 0px;
    width: 155px;
  }

  @media (min-width: 1200px) {
    height: 70px;
    width: 155px;
  }
`;

const defaults = {
  heroDesktop: placeholder_url(2680,490),
  heroTablet: placeholder_url(1600,400)
}

// TODO support all the "cutaway styles"
const Hero = ({
  showHeroImage = "standard",
  contentOrder = { desktop: "first", mobile: "last" },
  imgDesktop = defaults.heroDesktop,
  imgTablet = defaults.heroTablet,
  imgAlt,
  imageUrl,
  prehead,
  h1,
  posthead,
  body,
  ctas,
  children,
  ...props
}) => {
  if (!showHeroImage || showHeroImage === "none") {
    return <NoImageHero prehead={prehead} h1={h1} posthead={posthead} bodyCopy={body} />;
  }

  let processedImgDesktop = Picture.DefaultOrCrop(imgDesktop?.file, imgDesktop?.crop);
  let processedImgTablet = Picture.DefaultOrCrop(imgTablet?.file, imgTablet?.crop);
  let altText = imgAlt || imgDesktop?.alt;

  if (showHeroImage === "small") {
    let arrangement = {
      alignment: "center",
      containerSize: { selection: "full" },
      contentOrder: contentOrder.desktop,
      contentOrderMobile: contentOrder.mobile,
      // PB-727: When the initial default imgDesktop prop placeholder is removed,
      // we want no image to show; this prevents the C&I component from rendering
      // its own additional placeholder when the hero image prop is undefined
      layout: (processedImgDesktop ? "2-1" : "noMedia")
    };
    let content = {
      prehead: prehead,
      heading: h1,
      headingClass: "tier3",
      headingTag: "h1",
      posthead: posthead,
      bodyCopy: body,
      bodyFont: false,
      ctas: ctas
    };
    let media = {
      resources: { image: { file: processedImgDesktop, alt: altText } },
      type: "image"
    };

    return (
      <CMSContentAndImage
        arrangement={arrangement}
        content={content}
        imageUrl={imageUrl}
        media={media}
      />
    );
  }

  return (
    <HeroContainer aria-label={imgAlt} imgDesktop={processedImgDesktop} imgTablet={processedImgTablet} role="img" {...props}>
      <HeroGrid>
        <HeroPageHeading>
          {prehead && <span className="prehead tier3"><Text content={ prehead } /></span>}
          <H1 className="tier3">
            <RichText content={h1} inline />
          </H1>
          {posthead && <PostHead><Text content={ posthead } /></PostHead>}
        </HeroPageHeading>
        <BottomRightFill />
      </HeroGrid>
    </HeroContainer>
  );
}

export default Hero;
