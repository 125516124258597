import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Theme from "../../../themes";

// Components
import { Mobile, Tablet, Desktop } from "./internals/Devices";
import { Screens } from "../../PageBuilder/editing/Viewport";
import { H3 } from '../oc-h';
import Modal from "../../Modal";
import Video from "../oc-video";
import Picture from "../oc-picture";
import BasicContent from "./BasicContent";
import ImageShareLinks from "../oc-image-share-links";
import GalleryCarousel from "../../ComponentLibrary/oc-gallery-carousel";

// Utilities
import { is_placeholder_url, placeholder_aspect } from '../../../placeholder';
import placeholder_url from '../../../placeholder';
import withDefaultPrevented from "../../../withDefaultPrevented";

const Sizes = {
  'xl': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 1170 },
  'l': { [Mobile]: 345, [Tablet]: 738, [Desktop]: 970 },
  'm': { [Mobile]: 225, [Tablet]: 482, [Desktop]: 770 },
};

// Styled Components
const GalleryWrapper = styled.div`
  display: flex;
  justify-content: ${ (props) => props.alignment === 'Left' ? 'flex-start' : 'center' };
`;

const GallerySet = styled.div`
  margin: 0;
  width: ${ ({ width }) => `${ width.Mobile }px` };
  @media (min-width: ${ Screens.Tablet }px) { width: ${ ({ width }) => `${ width.Tablet }px` }; }
  @media (min-width: ${ Screens.Desktop }px) { width: ${ ({ width }) => `${ width.Desktop }px` }; }
`;

const GalleryContainer = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(${ (props) => (props.size === "small" ? "2" : "1") }, 1fr);
  height: auto;

  @media (min-width: 768px) {
    grid-template-columns: ${ (props) => props.size === "small" ? 'repeat(auto-fill, 160px)' : 'repeat(auto-fill, 224px)' };
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    justify-content: ${ (props) => props.alignment === 'Center' ? 'center' : 'start' };
    margin: 0 auto;
    width: ${ (props) => props.containerSize === 'xl' && props.alignment === 'Center' ? '90%' : '100%' };
  }

  @media (min-width: 1200px) {
    grid-template-columns: ${ (props) => props.size === "small" ? 'repeat(auto-fill, 168px)' : 'repeat(auto-fill, 268px)' };
    justify-content: ${ (props) => (
      props.alignment === 'Center' && props.size === 'small'
      ? 'end' : props.alignment === 'Center' && props.size === 'large'
      ? 'center' : 'start'
    )};
    max-width: 1340px;
  }
`;

const ThumbnailDiv = styled.div`
  &.carousel-thumbnail {
    a {
      &.image:before {
        content: "\\f03e";
      }
    }
  }

  &.large {
    a:before {
      @media (min-width: 768px) {
        font-size: 18px;
        height: 48px;
        width: 48px;
      }

      @media (max-width: 767px), (min-width: 1050px) {
        font-size: 24px;
        height: 64px;
        width: 64px;
      }
    }
  }

  &.small {
    a:before {
      @media (max-width: 767px), (min-width: 768px) {
        font-size: 12px;
        height: 32px;
        width: 32px;
      }

      @media (min-width: 1050px) {
        font-size: 15px;
        height: 40px;
        width: 40px;
      }
    }
  }

  a {
    border: 1px solid #d1d1d1;
    cursor: pointer;
    display: block;
    margin-bottom: 8px;
    position: relative;

    &:before {
      align-items: center;
      background: transparent;
      color: white;
      content: "";
      display: flex;
      font-family: "fontawesome";
      justify-content: center;
      left: 0;
      position: absolute;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
      top: 0;
      z-index: 10;
    }

    &:after {
      background: transparent;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &.active {
      border: 4px solid ${Theme.colors.brand};
    }

    &.image:before {
      content: "\\f0b2";
    }

    &.video:before {
      content: "\\f03d";
    }

    &:hover {
      &:before {
        background-color: white;
        color: ${Theme.colors.brand};
      }

      &:after {
        background: ${Theme.colors.brand};
        opacity: 0.5;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const GalleryModal = styled(Modal)`
  .text {
    margin: 16px;
  }

  img {
    display: block;
    width: 100%;
  }

  h3 {
    margin-bottom: 0;
  }

  &.image a.close {
    color: white;
    position: absolute;
    right: -10px;
    text-shadow: 0px 0px 5px rgb(0 0 0 / 50%);
    top: -10px;
    z-index: 1;
  }

  &:not(.image) {
    .modal-content {
      margin-top: 48px;
    }

    a.close {
      margin: 0;
      padding: 18px 16px;
    }
  }
`;

const Media = styled.div`
  position: relative;
`;

const Description = styled.div`
  font-size: 16px;
  inline-size: 100%;
  overflow-wrap: break-word;
`;

const Gallery = ({ copy, settings, items = [], socialShare }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [heading, setHeading] = useState(null);
  const [prehead, setPrehead] = useState(null);
  const [media, setMedia] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselItem, setCarouselItem] = useState(items[0]);

  const galleryCarousel = settings.galleryCarousel || false;
  const size = settings?.containerSize?.selection || 'xl';
  const Width = Sizes[size];
  const alignment = settings?.alignment || 'Left';

  const openModal = (heading, prehead, media, youtubeID) => {
    setHeading(heading);
    setPrehead(prehead);
    setIsVideo(!!youtubeID);
    setMedia(
      (() => {
        if (youtubeID) return <Video src={ youtubeID } />;

        let newImage = calculatedImage(media?.resources?.image?.file, media?.resources?.image?.crop)

        return media?.resources?.image?.file ? (
          <Media>
            <Picture source={newImage} alt={heading} />
            {socialShare && <ImageShareLinks imageUrl={newImage} />}
          </Media>
        ) : null;
      })()
    );
    setModalOpen(true);
  };

  const updateCarouselMedia = (index) => {
    setCarouselIndex(index);
    setCarouselItem(items[index]);
  };

  const navigateCarouselMedia = (direction = "next") => {
    let length = items.length;
    let newIndex = (direction === "next") ? carouselIndex + 1 : carouselIndex - 1;
    if (newIndex === length) newIndex = 0;
    if (newIndex === -1) newIndex = length - 1;

    updateCarouselMedia(newIndex);
  };

  const calculatedImage = (file, crop) => {
    if (settings?.aspectRatio === undefined || settings?.aspectRatio === null) {
      return file;
    }

    const aspectRatioTransform = Picture.AspectRatioTransform(settings.aspectRatio);
    if (is_placeholder_url(file) && aspectRatioTransform) {
      file = placeholder_aspect(file, aspectRatioTransform.aspectRatio)
    }

    if (crop) {
      file = Picture.Transform(file, { crop: 'crop', ...crop })
    }

    return Picture.Transform(file, [aspectRatioTransform, { width: file?.width, crop: 'fill' }]);
  }

  const Thumbnail = (uploader, index) => {
    uploader = uploader || {};
    uploader.media = uploader.media || {};

    let thumbnailClasses = [settings.thumbnail];
    if (galleryCarousel) thumbnailClasses.push("carousel-thumbnail");

    let thumbnailLinkClasses = [(uploader.media.type === 'image' ? 'image' : 'video')];
    if (galleryCarousel && (index === carouselIndex)) thumbnailLinkClasses.push("active");

    if (uploader.media.type === 'video') {
      console.error('Gallery component only supports YouTube videos for now')
      return null;
    }

    return (
      <ThumbnailDiv
        className={thumbnailClasses.join(" ").trim()}
        key={`${ uploader.thumbnail }-${ index }`}
      >
        <a
          className={thumbnailLinkClasses.join(" ").trim()}
          data-track="zoom"
          data-track-image-name={uploader.media.resources?.image?.alt}
          onClick={ withDefaultPrevented(() =>
            galleryCarousel ? updateCarouselMedia(index) :
            openModal(
              uploader.heading ||
                uploader.thumbnail ||
                uploader.media.resources?.image?.alt ||
                "Header",
              uploader.prehead,
              uploader.media.type === 'image' ? uploader.media : null,
              uploader.media.type === 'image' ? null : uploader.media.resources?.youtube?.url
            )
          )}
          title={ uploader.media.resources?.image?.alt || uploader.thumbnail }
        >
          { uploader.media.type === 'youtube' ? (
            <img
              alt={ uploader.media.resources?.image?.alt || uploader.thumbnail }
              src={
                `https://i.ytimg.com/vi/${uploader.media.resources?.youtube?.url}/mqdefault.jpg` ||
                placeholder_url(1920,1080)
              }
            />
          ) : (
            <Picture
              alt={ uploader.media.resources?.image?.alt || uploader.thumbnail }
              source={ uploader.media.resources?.image?.file }
              transform={{
                aspectRatio: "16:9",
                crop: "fill",
                quality: '60'
              }}
              variants={[
                {
                  width: 486,
                  media: '(min-width: 1200px) and (min-resolution: 2dppx)',
                  quality: 30
                },
                {
                  width: 243,
                  media: '(min-width: 1200px)',
                  quality: 60
                },
                {
                  width: 452,
                  media: '(min-width: 768px)',
                  quality: 30
                },
                {
                  width: 856,
                  media: '(min-width: 428px)',
                  quality: 20
                },
                {
                  width: 690,
                  media: '(min-width: 375px)',
                  quality: 20
                },
                {
                  width: 580,
                  quality: 20
                }
              ]}
            />
          )}
        </a>
        <Description size={ settings.thumbnail } >
          { uploader.thumbnail || uploader.media.resources?.image?.alt || `Thumbnail ${index}` }
        </Description>
      </ThumbnailDiv>
    );
  };

  // PB-495: Refresh carouselItem state on items change
  useEffect(() => {
    let item = items[carouselIndex] || items[(items.length - 1)];
    setCarouselItem(item);
  }, [items]);

  return (
    <GalleryWrapper alignment={ alignment }>
      <GallerySet width={ Width } size={ size }>
        <GalleryModal
          className={ `no-borders ${ !isVideo && "image" }` }
          open={ modalOpen }
          onClose={ () => setModalOpen(false) }
          name={ heading }
        >
          { media }
          <div class="text">
            { heading && <H3 prehead={ prehead } >{ heading }</H3> }
          </div>
        </GalleryModal>

        <BasicContent content={ copy } />

        {carouselItem && galleryCarousel &&
          <GalleryCarousel
            carouselItem={carouselItem}
            socialShare={socialShare}
            navigateCarouselMedia={navigateCarouselMedia}
          />
        }

        <GalleryContainer size={ settings.thumbnail } containerSize={ size } alignment={ alignment }>
          {items &&
            items.map((item, index) => {
              return Thumbnail(item.uploader, index);
            })
          }
        </GalleryContainer>
      </GallerySet>
    </GalleryWrapper>
  );
};

export default Gallery;
