import { define, ui } from '@owenscorning/pcb.alpha';

import Renderer from '../../../../OC/PageBuilder/Translation.jsx';


export default define`Translation`('0.0.1')({
  thumbnail: ui`Modules/Page/Thumbnails/Translation`,
  category: ui`Modules/Categories/Page/Prebuilt`,
  view: ({ value }) => <Renderer { ...value } />,
  edit: {},
});
